import axios from 'axios';
import { getCookie } from './cookies';

// Function to configure Axios
export const configureAxios = () => {
  const token = getCookie('accessToken');

  if (token) {
    // Set default headers for all requests
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  } else {
    console.warn('Access token is missing.');
    // Optionally handle missing token
  }
};

// Call the function to configure Axios immediately if token is already available
configureAxios();

// Export both the function and Axios instance
export {axios};
