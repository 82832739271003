import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import "./SearchableSelect.css";

const SearchableSelect = ({
  value,
  onChange,
  name,
  label,
  options,
  required,
  disabled,
  error,
  isMulti,
}) => {
  const handleOnChange = (e) => {
    if (e === undefined || e.length === 0)
      return onChange(e, { id: "", name: "" });
    if (isMulti) {
      const selectedOption = e.map((option) => {
        return options[Number(option)];
      });
      onChange(e, selectedOption);
    } else {
      const selectedOption = options[Number(e)];
      onChange(e, selectedOption);
    }
  };

  return (
    <div className="search-input-container" style={{ color: "black" }}>
      <label
        className="input-label"
        htmlFor={name}
        style={{ fontFamily: "Gotham Rounded" }}
      >
        {label} {required && "*"}
      </label>
      <Select
        name={name}
        value={value || undefined}
        placeholder={`Please select the ${label}`}
        filterOption={(value, option) => {
          if (option.children.toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
          return false;
        }}
        onChange={handleOnChange}
        className="search-input"
        bordered={false}
        showSearch={true}
        allowClear={required ? false : true}
        required={required}
        disabled={disabled}
        mode={isMulti ? "multiple" : "default"}
        style={error.length > 0 ? { border: "1px solid red" } : {}}
      >
        {options &&
          options.map((option, index) => (
            <option
              value={option.value}
              key={index}
              style={{ fontFamily: "Gotham Rounded, sans-serif" }}
            >
              {option.name}
            </option>
          ))}
      </Select>
      <div className="input-error">{error}</div>
    </div>
  );
};

SearchableSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  isMulti: PropTypes.bool,
};

SearchableSelect.defaultProps = {
  value: "",
  onChange: () => {},
  name: "",
  label: "",
  options: PropTypes.any,
  disabled: false,
  required: false,
  error: "",
  isMulti: false,
};

export default SearchableSelect;
