import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import Button from "../../../components/common/button/Button";
import Table from "../../../components/common/table/Table";
import { routeNames } from "../../../consts/routesNames";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./DisplayProperties.css";
import { createMenuItem } from "../../../helpers/menuHelper";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/modal/modalActions";
import ConfirmationBox from "../../../components/common/confirmationBox/ConfirmationBox";
import { navtabs } from "../../../consts/navTabs";
import { setActiveNavTab } from "../../../redux/navbar/navbarActions";
import { getAllProperties } from "../../../apis/propertiesApis/getAllProperties";
import { deleteProperty } from "../../../apis/propertiesApis/deleteProperty";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { getCookie } from "../../../utils/cookies";

function DisplayProperties({ homePageDisplay, projectStatus }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userRole = useRef(getCookie("userRole"));

  const [propertiesData, setPropertiesData] = useState([]);

  useEffect(() => {
    if (location.pathname === routeNames.PROPERTIES) {
      dispatch(setActiveNavTab(navtabs.PROPERTIES));
    }
  }, [location.pathname, dispatch]);

  // === Getting Table Data  ===
  const getProperties = useCallback(async () => {
    try {
      const allPropertiesRes = await getAllProperties(projectStatus);
      if (
        allPropertiesRes.error_code === 2 ||
        allPropertiesRes.error_code === 3
      ) {
        dispatch(
          openModal(
            <ResponseModal
              text={allPropertiesRes.error_message}
              isError={true}
            />
          )
        );
      } else {
        let propertiesArray = [];
        if (allPropertiesRes.res.data.length > 0) {
          propertiesArray = allPropertiesRes.res.data.map((property) => ({
            ...property,
            type_name: property.type.name,
            address: `${property.street_number} ${property.street_name}, ${property.city}, ${property.state} ${property.zip_code}`,
          }));
        }
        setPropertiesData(propertiesArray);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  }, [dispatch, projectStatus]);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  const tableHeaders = [
    {
      key: "address",
      name: "Full Address",
    },
    {
      key: "status",
      name: "Status",
    },
    {
      key: "section",
      name: "Section",
    },
    {
      key: "lot",
      name: "Lot",
    },
    {
      key: "block",
      name: "Block",
    },
    {
      key: "plan_number",
      name: "Plan Number",
    },
    {
      key: "living_sq_ft",
      name: "Living SF",
    },
    {
      key: "total_sq_ft",
      name: "Total SF",
    },
    {
      key: "completion_percentage",
      name: "Completion Percentage",
      formatter: (row) => {
        const value = parseFloat(row.completion_percentage);
        return isNaN(value) ? "0.00%" : `${value.toFixed(2)}%`; // Format to 2 decimal places
      },
    },
    {
      key: "type_name", // Handled Differently
      name: "Type",
    },
    {
      key: "close_date",
      name: "Close Date",
    },
  ];
  // === Click Button Action Handlers ===
  const HandlePropertyDetailsClick = (rowData) => {
    navigate(routeNames.PROPERTY_DETAILS, { state: { data: rowData } });
  };

  const HandleAddNewPropertyClick = () => {
    userRole.current !== "others" && navigate(routeNames.ADD_PROPERTY);
  };

  // === Delete Property Action ===
  const confirmDeleteProperty = (rowData) => {
    dispatch(
      openModal(
        <ConfirmationBox
          msg={`Are you sure you want to delete this project?`}
          saveText={"OK"}
          discardText={"Cancel"}
          saveAction={async () => {
            try {
              await deleteProperty(rowData.id);
              errorDispatcher("Deleted Successfully");
              getProperties();
            } catch (error) {
              console.error("Error deleting property:", error);
              errorDispatcher("Failed to delete the property.");
            }
          }}
        />
      )
    );
  };

  const errorDispatcher = (message) => {
    alert(message);
    dispatch(openModal(<ResponseModal text={message} isError={true} />));
  };

  // === Edit Property Action ===
  const editProperty = (rowData) => {
    navigate(`${routeNames.EDIT_PROPERTY.split(":")[0]}${rowData.id}`, {
      state: { data: rowData },
    });
  };

  return (
    <>
      <div
        className={
          homePageDisplay
            ? "main-container-displayProperties-homePageDisplay"
            : "main-container-displayProperties"
        }
      >
        {!homePageDisplay ? (
          <div className="btns-main-container-displayProperties">
            <div className="add-btn-container-displayProperties">
              <Button
                className={"add-btn-displayProperties"}
                buttonText={"Add New Project"}
                onClick={HandleAddNewPropertyClick}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="table-main-container-displayProperties">
          <Table
            tableHeight={homePageDisplay ? "450px" : "650px"}
            headers={tableHeaders}
            rows={propertiesData}
            searchable={homePageDisplay ? false : true}
            onRowClick={HandlePropertyDetailsClick}
            rowActions={
              userRole.current !== "others" && [
                createMenuItem("Edit", editProperty, <EditIcon />),
                createMenuItem("Delete", confirmDeleteProperty, <DeleteIcon />),
              ]
            }
          />
        </div>
      </div>
    </>
  );
}

export default DisplayProperties;
