import urlConfig from "../../config.json";
import { BACKEND_URL } from "../../constants";
import { setCookie, getCookie } from "../../utils/cookies";
import {axios , configureAxios} from "../../utils/axiosConfig";

export const login = async (email, password) => {
  const ROUTE = urlConfig.backend.routes.authentication.login;
  const url = `${BACKEND_URL}${ROUTE}`;
  const reqData = {
    email: email,
    password: password,
  };

  return await axios
    .post(url, reqData)
    .then((res) => {
      if (res.status === 200) {
        setCookie("accessToken", res.data.access_token, 1);
        setCookie("refreshToken", res.data.refresh_token, 7);
        setCookie("userId", res.data.user_id, 1);
        setCookie("userEmail", res.data.email, 1);
        setCookie("firstName", res.data.first_name, 1);
        setCookie("lastName", res.data.last_name, 1);
        setCookie("phoneNumber", res.data.phone_number, 1);
        setCookie("userRole", res.data.role, 1);
        // make sure to call axios configuration after login to set the cookie token as it is loaded firstly in this page without a token
        // so it is saved as none firstly because we don't have a token before login, so we need to reconfigure axios again after we have the token
        if (getCookie("accessToken")) {
          configureAxios();      
          return { res: res.data, error_code: 1 };
        }
        else {
          return {
            error_code: 2,
            error_message: "Token is not saved",
          };
        }
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return {
          error_code: 2,
          error_message: "Email and Password fields are required",
        };
      } else if (error.response.status === 422) {
        return {
          error_code: 3,
          error_message: "Email or Password is incorrect.",
        };
      } else {
        alert(error.response.data.message);
      }
    });
};
